import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'
import VueTheMask from 'vue-the-mask'

import App from './App.vue'

import http from './helpers/http'
import api from './helpers/api'

import router from '@/router/index'

const pinia = createPinia()

var application = createApp(App)

application.use(pinia)
application.use(Notifications)
application.use(VueTheMask)
application.use(router)

application.mount('#app')
application.provide('$api', api)
application.provide('$http', http)