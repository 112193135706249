<template>
    <header>
        <div class="container header__container">
            <div class="header__menu"></div>
            <nav>
                <router-link class="nav__li" to="/" :class="$route.path == '/' ? 'nav__li_active' : ''">Прокат изделий</router-link>
                <router-link class="nav__li" to="/dashboard" :class="$route.path == '/dashboard' ? 'nav__li_active' : ''">Дашборд</router-link>
                <router-link class="nav__li" to="/audit" :class="$route.path == '/audit' ? 'nav__li_active' : ''">Журнал аудита</router-link>
                <router-link class="nav__li" to="/knowledge" :class="$route.path == '/knowledge' ? 'nav__li_active' : ''">База знаний</router-link>
                <router-link class="nav__li" to="/condition" :class="$route.path == '/condition' ? 'nav__li_active' : ''">Состояние</router-link>
                <router-link class="nav__li" to="/settings" :class="$route.path == '/settings' ? 'nav__li_active' : ''">Настройки</router-link>
            </nav>
            <router-link v-if="authStore.access_token == null" class="nav__li nav__li_auth" to="/auth">Вход</router-link>
            <div  v-else class="nav__li nav__li_auth" @click="logout()">Выйти</div>
        </div>
    </header>
</template>
<script setup>
import './Header.scss'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import auth from '@/helpers/auth.js'
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore()

let isAuth = ref({state: false, login: ''})

let authHandle = () => {
    if(sessionStorage.getItem('access_token') != null) {
        isAuth.value.state = true
        isAuth.value.login = sessionStorage.getItem('login')
    }
    authStore.loadingToken()
}
onMounted(authHandle)

let logout = () => {
    auth.signOut()
}

const route = useRoute()

watch(
      () => route.path,
      async path => {
        authHandle()
      }
    )
</script>