import http from './http'

export default {
    callMethod(type, method, params = {}, headers = {}, is_prefix = true) {
        return new Promise(async (resolve, reject) => {
            //const url = (process.env.NODE_ENV == 'development' ? 'http://' + document.location.host.split(':')[0] : '') + '/api/' + method
            const url = (process.env.NODE_ENV == 'development' ? 'https://test.shtil.dev/api' : '/api') + '/' + method
            //const url = (process.env.NODE_ENV == 'development' ? 'http://localhost' : '') + (is_prefix ? '/api/' : '/') + method
            try {
                let result = await http.call(type, url, params, headers)
                resolve(result)
            } catch(e) {
                reject(e)
            }
        })
    }
}
