import api from '@/helpers/api.js'


export default {
    getHeader: () => {
        if(sessionStorage.getItem('access_token') != null && sessionStorage.getItem('access_token').length > 0) {
            return {'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')}
        }
        return {}
    },
    signOut: async () => {
        try {
            await api.callMethod("GET", 'Users/SignOut', {}, this.getHeader())
        } catch(e) {

        }
        location.reload()
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('login')
    }
}