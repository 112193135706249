<template>
  <notifications />
  <v-header />
  <router-view></router-view>
  <v-footer></v-footer>
</template>

<style>

</style>

<script setup>
import '@/assets/main.scss'

import vHeader from '@/components/Header/Header.vue'
import vFooter from '@/components/Footer/Footer.vue'
import { useSocketStore } from '@/stores/socket'
import { onMounted } from 'vue';

onMounted(() => {
  const socketStore = useSocketStore();

  socketStore.connect();
})
</script>