import { createWebHistory, createRouter } from "vue-router";


const routes = [
  {
    path: "/",
    name: "Rolling",
    component: () => import("@/views/Rolling/Rolling.vue"),
  },
  {
    path: "/knowledge",
    name: "Knowledge",
    component: () => import("@/views/Knowledge/Knowledge.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/Auth/Auth.vue"),
  },
  {
    path: "/audit",
    name: "audit",
    component: () => import("@/views/Audit/Audit.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard/Dashboard.vue"),
  },
  {
    path: "/condition",
    name: "condition",
    component: () => import("@/views/Condition/Condition.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings/Settings.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router