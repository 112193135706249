import { defineStore } from "pinia"

export const useAuthStore = defineStore('authStore', {
    state: () => {
        return {
            access_token: null
        }
    },
    actions: {
        loadingToken() {
            this.access_token = sessionStorage.getItem('access_token')
        }
    }
})