import { defineStore } from "pinia"

export const useSocketStore = defineStore('socketStore', {
    state: () => {
        return {
            socket: null,
            callbacks: []
        }
    },
    actions: {
        connect() {
            if (this.socket == null) {
                //this.socket = new WebSocket("ws://test.shtil.dev:8001");
                 //this.socket = new WebSocket("wss://" + 'test.shtil.dev' + ":8001");
                 this.socket = new WebSocket("ws://" + (process.env.NODE_ENV == 'development' ? '188.234.213.236' : document.location.host.split(':')[0]) + ":8001");
                this.socket.onmessage = (e) => {
                    for (let callback of this.callbacks) {
                        callback.func(JSON.parse(e.data))
                    }
                }
            }
            // setTimeout(() => {
            //     let obj = JSON.stringify({
            //             "eventType": "EventMessageDeleted",
            //             "payload": {
            //                 "id": 7,
            //                 "measurementId": null,
            //                 "type": {
            //                   "type": "\u0414\u0440\u0443\u0433\u043E\u0439 \u0442\u0438\u043F",
            //                   "name": "\u0414\u0440\u0443\u0433\u043E\u0439 \u0442\u0438\u043F",
            //                   "destination": "StatusRight",
            //                   "lvl": "Critical"
            //                 },
            //                 "text": "Common Test Message",
            //                 "dateTime": "2022-09-14T01:13:41.656",
            //                 "guk": 2,
            //                 "isCompleted": false                        
            //               }
            //     })
            //     this.socket.onmessage({data: obj})
            // }, 5000)
        },
        
        addCallback(key, func) {
            let flag = false
            for (let callback of this.callbacks) {
                if (callback.key == key) {
                    flag = true
                    break;
                }
            }
            if (!flag) {
                this.callbacks.push({key: key, func: func})
            }
        }
    }
})